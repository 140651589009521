@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root{
  --green: #66CC66;
  --black: black;
  --black2: #141414;
  --grey: #D8D8D8;
  --greyBackground: #DDDDDD;
  --white: #FFFFFF;
  --red: red;
}


body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.625em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3,
h4, h5, h6 {
  font-family: 'Montserrat', sans-serif;
  line-height: 1.2em !important;
  margin-bottom: 0;
  margin-top: 0;
  font-weight: bold;
}

.h1, .h2, .h3,
.h4, .h5, .h6 {
  margin-bottom: 0;
  margin-top: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
}

h1, .h1 {
  font-size: 45px;
}

h2, .h2 {
  font-size: 35px;
}

h3, .h3 {
  font-size: 24px;
}

h4, .h4 {
  font-size: 18px;
}

h5, .h5 {
  font-size: 16px;
}

h6, .h6 {
  font-size: 14px;
}

a {
  text-decoration: none;
  color:var(--green)
}

a:visited{
  color:var(--green)
}

a:hover{
  color:var(--white)
}


section{
  padding: 2% 0;
}
section#case-history{
  display: block;
  background-color: white;
}

textarea{
  margin: 0;
}
