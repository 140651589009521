.container {

    position: relative;
    display: block;
    align-items: center;
    color: #fff;
}

.mainContainer {
    position: relative;
    aspect-ratio: 16/9;
    display: flex;
    align-items: center;
    color: #fff;
}

.mainContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;

}

.mainContainer .fullImg {
    display: block;
    padding: 0;
}

.container img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}

.container .fullImg {
    display: inline-block;
    padding: 0;
}

.container .fullImg:hover {
    cursor: pointer;
    display: block;
    padding: 0;
}

.container .fullImg img {
    object-fit: cover;
}

.container .fullImg .overlay {
    position: absolute;
    width: 100%;
    bottom: -3px;
    text-transform: uppercase;
    transition: .5s ease;
    opacity: 0;
    color: white;
    text-align: center;
}

.fullImg:hover .overlay {
    cursor: pointer;
    opacity: 1;
}

.text {
    text-align: left;
    color: var(--black);
}

.date {
    color: var(--black);
    font-style: italic;
}

.services {
    display: flex;
    flex-flow: column nowrap;
    width: 33%;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: .3s ease;
    margin: 0px 5%;
}

.services:hover {
    transform: translateY(-10px) scale(1.02);
}

.services:hover .title {
    background-position: -98% 0;
    color: var(--white)
}

.serviceImage {
    aspect-ratio: 2/3;
    width: 20rem;
    object-fit: cover;
}

.serviceText {
    width: 80%;
    text-align: justify;
}

.title {
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 50%, rgb(0, 0, 0) 50%);
    background-size: 202%;
    background-position: 0 0;
    display: inline;
    transition: 0.5s ease-in-out;
    font-family: raleway, arial, sans-serif;
    margin: 2% 0;
    padding: 3% 1%;
    font-size: 1.4em;
    text-align: center;
}

.title a {
    color: white;
    text-decoration: none;
}

.activeTitle {
    background: var(--black);
    font-family: raleway, arial, sans-serif;
    margin: 2% 0;
    padding: 3% 3%;
    color: var(--white);
    text-align: center;
    font-size: 1.4em;
}

.activeTitle a {
    color: white;
    text-decoration: none;
}

/*
.title{
    margin: 2% 0;
    padding: 3% 3%;
    font-size: 30px;
    transform: perspective(1px) translateZ(0);
    display: inline-block;
    transition: color 0.3s;
    position: relative;
}

.title::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background: var(--black);
    transform: scaleX(0);
    transform-origin: 0 50%;
    transition: transform 0.3s ease-out;
}

.title:hover{
    color: var(--white);
}

.title:hover::before {
    transform: scaleX(1);
}
*/

@media (max-width: 1400px) {
    .container .fullImg .overlay {
        bottom: -1px;
        font-size: 30px;
    }

    .serviceImage {
        width: 15rem;
    }

}

@media (max-width: 900px) {

    .text {
        font-size: 14pt;
        /* font-weight: bold; */
        text-align: left;
        color: var(--black);
    }

    .mainContainer {
        aspect-ratio: 1/1;
    }

    .container .fullImg .overlay {
        position: absolute;
        width: 100%;
        /* bottom: -2px; */
        text-transform: uppercase;
        transition: .5s ease;
        opacity: 1;
        color: white;
        text-align: center;
    }

    .services {
        /* margin-top: 5%; */
        margin: 0px;
        width: 45%;
    }


}

@media(max-width: 767px){
    .services {
        width: 90%;
    }
}

@media (max-width: 600px) {
    .services {
        margin: 0px;
        width: 90%;
    }

    .activeTitle {
        text-align: center;
    }
}


/* --- SOLO PER CHROME --- */

@media screen and (-webkit-min-device-pixel-ratio:0) {
    .mainContainer {
        position: relative;
        display: flex;
        align-items: center;
        color: #fff;
    }

}