.expandable-paragraph-text {
    text-align: justify;
    overflow: hidden ;
}

.expandable-paragraph-button {
    width: 100%;
    background: transparent;
    border: 0;
    color: var(--green);
    font-size: medium;
    cursor: pointer;
    text-align: right;
}

.expandable-paragraph-button-hidden {
    display: none;
}