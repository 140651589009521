.container{
    padding: 0 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}


.arrowBack{
    width: 10%;
    margin-right: 10%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    font-size:2rem;
    transition: 0.7s;
}

.arrowBack a{
    color: black;
}

.arrowBack:hover {
    cursor: pointer;
    transform: translate(-1.5rem);
    transition: 0.7s;
    color: black;
}

.sideTitle{
    width: 100%;
}

.grid {
    display: grid;
    width: 80%;
    max-width: 1280px;
    grid-template-columns: repeat(3, minmax(0, 2fr));
    gap: 2rem;
    margin: 2% auto;
}

.page {
    text-align: justify;
    padding: 0 10%;
}

.subsection{
    margin: 2% 0;
}

.bio{
    margin-top:5%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.bioImage {
    height: 20rem;
}

.bioPage{
    margin: 0 5%;
}

.title{
    display: flex;
    flex-flow: row nowrap;
    text-transform: uppercase;
    align-items: baseline;
    align-self: flex-start;
}

.carousel{
    padding: 2% 0;
}

.awardIcon{
    width: 3rem;
    margin: 0 2%;
}

.awards{
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin: 2% 0 0 -2%;
}

.icon {
    color: var(--green) !important;
    margin: 0 2%;
}

.icon:hover{
    cursor: pointer;
}

.links a{
    color: var(--black);
    margin: 2% 0 0 -2%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.links .icon{
    color: var(--green) !important;
}

.links:hover a{
    color: var(--green);
}

.video{
    display: flex;
    flex-flow: row ;
    overflow: auto;
}

.iframe{
    aspect-ratio: 16/9;
    margin: 2%;
}

.filters{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 90%;
    margin: 2rem 0 0 0;
    text-transform: capitalize;
}

.filter{
    color: var(--black);
    transition: all 0.5s ease 0s;
    margin-left: 2rem;
    cursor: pointer;
}

.filterActive{
    margin-bottom: 0.4rem;
    border-bottom: var(--green) solid 2px;
    color: var(--green);
    transition: all 0.5s ease 0s;
    margin-left: 2rem;
    cursor: pointer;
}

.filter:hover{
    margin-bottom: 0.4rem;
    border-bottom: var(--green) solid 2px;
    color: var(--green);
    transition: all 350ms ease;
}

.padding{
    width: 80%;
}

.socials{
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-evenly;
    align-items: flex-end;

}

.socialIcon{
    display: inline-block;
    height: 3.1rem;
    width: 3.1rem;
    background-color: var(--black);
    border-radius: 50%;
    text-align: center;
    font-size: 1.5rem;
    line-height: 3.5rem;
    margin: 1.5rem 0;
    margin-right: 1rem;
}

.text{
    text-align: justify;
    padding: 0 10%;
}

@media (max-width: 767px) {

    .grid {
        display: grid;
        width: 90%;
        grid-template-columns: repeat(1, 1fr);
        gap: 0.5rem;
        margin: 0 5%;
    }
    .bio{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .bioPage{
        margin: 5%;
    }

    .socials{
        flex-direction: row;
        height: auto;
        padding: 0 0 5% 0;
        justify-content: space-evenly;
        width: 100%;
    }

    .title{
        margin: 0 0 5% 0;
    }

    .subsection{
        margin: 5% 0;
    }

    .awardIcon{
        width: 2rem;
    }

    .carousel{
        padding: 5% 0;
    }

    .padding{
        padding: 5% 0 0 0;
    }

    .page{
        padding: 0 5%;
    }
}