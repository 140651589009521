.container{
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

}

.table{
    width: 100%;
}

.table li{
    border-radius: 5px;
    padding: 1% 3%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2%;
}

.tableHeader{
    background-color: var(--green);
    text-transform: uppercase;
    letter-spacing: 0.03em;
    font-weight: bold;
}

.table .xsmallCol{
    flex-basis: 5%;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 5px;
}

.table .xsmallCol{
    flex-basis: 5%;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 5px;
}

.table .smallCol{
    flex-basis: 10%;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 5px;
}

.table .mediumCol{
    flex-basis: 20%;
    font-size: 14px;
    margin-right: 5px;
}

.table .largeCol{
    flex-basis: 40%;
    font-size: 14px;
    margin-right: 5px;
}

.table a:hover {
    color: var(--black)
}

.tableRow{
    background-color: var(--white);
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.1);
    transition: transform .7s;
}

.tableRowReported{
    background-color: var(--grey);
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.1);
    transition: transform .7s;
    color: var(--black);
}

.tableRowReported:hover{
    cursor: pointer;
    transform: scale(1.1);
}

.tableRow:hover{
    cursor: pointer;
    transform: scale(1.1);
    background-color: var(--greyBackground);
}