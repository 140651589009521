.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 10% 0 10% !important;
    max-width: 1280px;
    margin: auto;
}



/* INSTAGRAM POSTS CONTAINER */
.mainPost{
    display: grid;
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
    gap: 2%;
    margin: 5% 10% 0 10%;
}

.grid{
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    gap: 2%;
    margin: 2% 10% 5% 10%;
}

@media (max-width: 767px) {
    .mainPost{
        display: grid;
        width: 100%;
        grid-template-columns: repeat(1, 1fr);
        gap: 1%;
        margin: 10%;
    }
    .grid{
        display: grid;
        width: 100%;
        grid-template-columns: repeat(1, 1fr);
        gap: 1%;
        margin: 5% 0 30% 0;
    }
}

/* --- SOLO PER CHROME --- */

@media screen and (-webkit-min-device-pixel-ratio:0) {

    /* INSTAGRAM POSTS CONTAINER */
    .mainPost{
        display: inherit;

    }
}
