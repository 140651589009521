.icons{
    color: var(--green);
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.icons a:hover{
    color: var(--white);
}

.item:hover{
    cursor: pointer;
    transform: scale(1.2);
    transition: 0.5s;
    color: var(--white);
}



@media (max-width: 767px) {
    .icons {
        padding: 10% 0;
        color: var(--green);
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }
}