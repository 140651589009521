.container{
    display: flex;
    flex-flow: row nowrap;
    padding: 2% 0 1% 0;
    margin-left: -20%;
    align-items: flex-start;
    justify-content: space-evenly;
}

.banner{
    margin-top: -60px;}

.carousel{
    padding: 10% 0;
    background: #fff;
}
.bottonicta a{
    display: inline-block;
    margin: 0px 5px;
}
.center{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5% 0;
}

@media (max-width: 767px) {
    .container{
        flex-wrap: wrap;
    }
}