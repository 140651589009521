.container{
    height: 100vh;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
    display: flex;
    flex-direction: column;
}

.section{
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.logoHome{
    width: 30%;
    animation-duration: 1s;
    animation-name: animate-fade; /* Nome dell'animazione in ingresso da richiamare in keyframes*/
    animation-delay: 0.5s;
    animation-fill-mode: backwards;
}

/*Per fare l'animazione di ingresso di logoHome*/

@keyframes animate-fade {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

.scrollDown{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--white);
    text-transform: uppercase;
    cursor: pointer;
}

.arrowDown{
    color: var(--green);
}

.arrowDown:hover{
    color:var(--white) ;
}


@media (max-width: 767px) {
    .logoHome {
        width: 100%;
    }
}