.container{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    overflow-x: scroll;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    padding: 0 10%;
    margin-top: 5%;
    background-image: linear-gradient(var(--white) 20%, var(--green) 20%, var(--green) 80%, var(--white) 80%);

}
.container::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}

.item{
    margin-left: 2.5%;
    margin-right: 2.5%;
}

.item:hover{
    cursor: pointer;
}

.itemActive{
    z-index: 2;
    padding: 1% 2.5%;
    background: var(--black);
    color: var(--white);
}

.itemActive:hover{
    cursor: pointer;
}


@media (max-width: 767px) {
    .container{
        margin: 10% 0;
        display: flex;
        justify-content: flex-start;
    }
}
