.container{
    display: flex;
    flex-direction: row;
}

.formInput {
    outline: none;
    padding: 0.7rem 2rem;
    width: 100%;
    background-color: var(--white);
    border: 2px solid var(--black)
}

.formInputError {
    outline: none;
    padding: 0.7rem 2rem;
    width: 100%;
    background-color: var(--white);
    border: 2px solid var(--black);
    color: var(--red)

}


.formInput:focus{
    border: 2px solid var(--green);
}
.formInputError:focus {
    border: 2px solid var(--green);
    color: var(--black)

}

.formInput .left input textarea{
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;

}

.formInput .right input textarea{
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.formInput::placeholder {
    font-weight: 500;
    font-size: 13px;
}

.icon{
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 10%;
    background: var(--black);
    color: var(--white);
}

@media (max-width: 767px){

    .formInput {
        padding: 0.7rem 1rem;
        width: 100%;
        background-color: var(--white);
    }

    .icon{
        width: 20%;
    }
}