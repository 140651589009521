.container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    width: 100%;
    height: 5rem;
    background: var(--black);
    color: var(--white) !important;
    /* margin-left: 10%; */
}

@media (max-width: 767px){
    .container{
        width: 100%;
        margin: 5% 0;
    }
}