
.details {
    padding: 0 10%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-image: linear-gradient(to bottom, var(--white) 25%, var(--grey) 25% 75%, var(--white) 25%);
    flex-grow: 1;
}

.section {
}

.subsection {
    margin: 3% 0;
    /* text-align: justify; */
}

.image {
    width: 35%;
}

.text {
    /* text-align: justify; */
    padding: 0 10%;
}

.link{
    font-weight: bold;
}

@media (max-width: 1280px){
    .details{
        text-align: center;
        padding: 2% 10%;
        display: block;
        background-image: linear-gradient(to bottom, var(--white) 10%, var(--grey) 10% 90%, var(--white) 10%);
        line-height: 5px;
    }
}

@media (max-width: 767px){
    .details{
        padding: 5% 10%;
        background-image: linear-gradient(to bottom, var(--white) 10%, var(--grey) 10% 90%, var(--white) 10%);
        text-align: justify;
        line-height: 20px;
    }
}