/*container con imagine*/
.container{
    margin-top: -20vh;
    height: 110vh;
    /* background: var(--black); */
    /* padding: 0 15% 80px 15% ; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    z-index: -100;*/
}

video{
    /* position: absolute;
    top: 0;
    left: 0; */
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 80%;
}

.section{
    position: absolute;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.logoHome{
    width: 20%;
    animation-duration: 1.5s;
    animation-name: animate-fade; /* Nome dell'animazione in ingresso da richiamare in keyframes*/
    animation-delay: 2s;
    animation-fill-mode: backwards;
}

/*Per fare l'animazione di ingresso di logoHome*/

@keyframes animate-fade {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

.scrollDown{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--white);
    text-transform: uppercase;
    cursor: pointer;
    top: 200px;
    position: relative;
}

.arrowDown{
    color: var(--green);
}

.arrowDown:hover{
    color:var(--white) ;
}


@media (max-width: 767px) {
    .logoHome {
        width: 50%;
    }
}