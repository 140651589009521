.container{
    height: 30rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background: var(--black);
    color: var(--white);
}

.icons{
    width: 30%
}

.logo{
    width: 10rem;
}

.credits{
    /* display: flex; */
    /* flex-direction: row; */
    justify-content: space-between;
    text-align: center;
    align-items: center;
    width: 90%;
    margin-top: 3%;
}

.menu{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 30%;
}

.item{
    text-transform: uppercase;
    font-weight: 800;
    font-size: 20px;
    color: var(--white);
    width: 50%;
}

.itemGreen {
    text-transform: uppercase;
    font-weight: 800;
    font-size: 20px;
    color: var(--green);
}


.item:hover{
    color: var(--green);
}

.productBy {
    color: var(--white) !important;
}

.productBy:hover {
    color: var(--green) !important;
}

.alignCenter{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

@media (max-width: 767px) {
    .menu{
        width: 100%;
    }
    .icons{
        width: 100%
    }

    .container{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        background: var(--black);
        color: var(--white);
        padding: 5% 5%;
        text-align: center
    }

    .credits{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        font-size: 13px;
    }
}
