.text{
    padding: 5% 10%;
    text-align: justify;
    /* background: url('../../data/img/studio/bgorso.webp'); */
    /* background-color: transparent; */
    background-size: 100%;
    background-position: top center;
    background-repeat: no-repeat;
}
.sfondolo{
    /* background-color: #000; */
    z-index: -5;
}
video{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 100%;
    z-index: -1;
}
.textlocation{
    padding: 3% 10%;
    text-align: justify;
    background-color: black;
}
.text img{
    width: 40%;
}

@media (max-width: 1000px) {    
    .text{
        padding-top: 20%;
    }
    .text img{
        width: 60%;
    }
    h2{
        text-align: center;
        font-size: 1.5em;
    }
    .mappina{
        display:none;
    }
}

.black{
    height: 5rem;
    background-color: var(--black);
}

.green{
    height: 5rem;
    background: var(--green) !important;
}
