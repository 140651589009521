.container{
    display: flex;
    flex-direction: column;
}

.top{
    display: flex;
    flex-direction: row;
    background: transparent;
    margin-bottom: -0.2rem;
}

.space{
    width: 90%;
}

.img{
    width: 5rem;
    padding: 0 5%;

}

.bottom{
    padding: 2.5% 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background: var(--green);
    text-align: justify;
    overflow: auto;
}