.question{
    display: flex;
    flex-flow: row;
    cursor: pointer;
    margin-bottom: 2%;
    width: 48%;
    transition-duration: 0.5s;
    min-height: 75px;
}

.questionActive {
    display: flex;
    flex-flow: row;
    cursor: pointer;
    margin-bottom: 2%;
    width: 100%;
    transition-duration: 0.5s;
    font-weight: bold;
}

.question:hover{
    cursor: pointer;
}

.icon{
    display: flex;
    flex-flow: row ;
    align-items: center;
    justify-content: center;
    max-width: 4rem;
    padding: 0.5rem;
    background: var(--black);
    color: var(--white);
}

.text{
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    background: var(--grey);
    flex-grow: 1;
    padding: 0.7rem 2rem;
    text-align: justify;
}

.img{
    width: 2.5rem;
}

.imgActive{
    width: 2.5rem;
}

.answer{
    text-align: justify;
    margin-bottom: 3%;
}

@media (max-width: 900px) {
    .question{
        width: 100%;
        margin-bottom: 5%;
    }
    .questionActive {
        width: auto;
        font-weight: normal;
        margin-bottom: 5%;
    }
}
