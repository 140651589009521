.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
}
.container{
    position: relative;
    width: 100%;
    max-width: 40rem;
    height: 45rem;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 1rem;
    overflow-y: scroll;
}

.body{
    color: black;
    justify-content: center;
    min-height: 20rem;
    width: 80%;
    padding: 5% 0;
}

.closeSection{
    margin-top: 2rem;
    margin-left: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 90%;
    cursor: pointer;
}

.closeButton{

}

.header{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-bottom: 1rem;
    align-items: center;
}

.cell{
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: flex-start;
}

.title{
    font-weight: bold;
}

.text{
    font-size: 12px;
    font-weight: bold;
    color: var(--black);
}
.text:hover{
    color: var(--green);
}

.buttons{
    padding: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.message{
    margin-top: 5%;
    overflow-y: scroll;
    max-height: 20rem;
    background: var(--greyBackground);
    padding: 1rem 2rem;
    font-size: 12px;
    font-style: italic;
}

.section{
    display: flex;
    flex-flow: column nowrap;
    width: 45%;
    margin: 3% 0;
}

.row{
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
}

input .row:hover{
    cursor: pointer;
}

.card{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

.isBot{
    margin: 0 0 2% 0;
    color: var(--red);
}

.isBot:hover{
    cursor: pointer;
    text-decoration: underline;
}

.textArea{
    width: 100%;
    padding: 2%;
    resize: vertical;
}

