.container{
    background: var(--green);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    max-width: 30%;
    height: 5rem;
    padding: 0 0 0 10%;
    color: var(--black);
    text-transform: uppercase;
    -webkit-box-shadow: 10px 10px 18px -6px #000000; 
    box-shadow: 10px 10px 18px -6px #000000;
}

a.linkSideTitle:hover{
    color: #fff !important;
}

@media (max-width: 1280px) {
    .container{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 0;
        margin: 10% 0 5% 0;
        text-align: center;
        max-width: 100%;
        /* -webkit-box-shadow: 10px 10px 18px -6px #000000; 
        box-shadow: 10px 10px 18px -6px #000000; */
    }
}