.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    padding: 3% 25%;
    background: var(--grey);
}

.box{
    padding: 3% 5%;
    border-radius: 0.5rem;
    border: 3px solid var(--green);
    background: var(--white);
}

.icon{
    padding: 1rem;
    border-radius: 50%;
    background: var(--green);
    margin-bottom: 1rem;
}

.form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title{
    margin-bottom: 3rem;
}

.row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 70%;
}
.row .inputs{
    width: 45%;
}

.inputs{
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;
    width: 70%;
    height: auto;
    font-size: 0.8em;
    color: red;

}

.label{
    font-size: 0.8rem;
    margin-bottom: 0.3rem;
    color: #bebebe;
}

.input{
    padding: 0 0.5rem;
    outline: none;
    border-radius: 2px;
    height: 2rem;
    width: 100%;
    border: none;
    border-bottom: 1px solid #efefef;
}

.text{
    padding: 1% 10%;
    text-align: justify;
}



.terms{
    padding: 3%;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: center;
    width: 50%;
    background: white;
}

.termsText{
    width: 80%;
    margin: 0 3%;
    text-align: justify;
}

::placeholder{
    font-size: 0.8rem;
    font-family: "Open Sans", sans-serif;
}

.help{
    padding: 3% 10%;
}

.admin{
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #141414;
}

@media (max-width: 1400px) {
    .container{
        width: 60%;
        padding: 10% 20%;
    }
}

@media (max-width: 900px) {
    .container{
        width: 80%;
        padding: 10%;
    }

    .termsText{
        width: 95%;
        margin: 0 3%;

    }
}

@media (max-width: 600px){
    .container{
        width: 100%;
        padding: 10% 0;
    }
    .termsText{
        width: 85%;
    }
    .terms{
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: justify;
        width: 90%;
    }
    .text{
        text-align: justify;
    }


    .help{
        padding: 3%;
        text-align: center;
    }
}