.searchBox{
    padding: 0.7rem;
    border: none;
    border-radius: 50%;
    background-color: var(--black);
    color: var(--white);
    text-transform: uppercase;
    font-size: 30px;
    font-weight: 800;
    z-index: 2;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.searchBox:hover {
    border-radius: 1rem;
    padding: 0.7rem;
}

.searchBox:hover > input{
    width: 10rem;
    padding: 0 1rem;
    border-radius: 0.5rem;
}



.icon:hover{
    cursor: pointer;
    transform: rotate(360deg);
    transition-duration: 0.5s;
    color: var(--green);
}

.input{
    width: 0;
    border:none;
    outline: none;
    padding: 0;
    background: none;
    font-size: 1.1rem;
    transition: 0.5s ease;
    color: white;
}