.dropdownContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    padding: 0 25%;
}

.selectContainer{
    display: flex;
    flex-direction: row;
}

.container{
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;
    width: 70%;
    height: auto;
    font-size: 0.8em;
}

.dropdownPrimary{
    text-align: center;
    padding-right: 0;
}

.placeholder{
    color: grey;
}

.price{
    font-size: 1.2em;
    text-align: center;
}

.promoprice{
    text-align: center;
    font-size: 0.7em;
}

.select{
    outline: none;
    padding: 0.7rem 2rem;
    width: 100%;
    background-color: var(--white);
    border: 2px solid var(--black);

}

.select:hover{
    cursor: pointer;
    border: 2px solid var(--green);
}

select .select option .item{
    padding: 0.5rem;
    transition: all 0.2s;
    color: var(--black);
    border: 2px solid var(--black);
    border-top: none;
}

.item{
    padding: 0.5rem;
    transition: all 0.2s;
    color: var(--black);
    border: 2px solid var(--black);
    border-top: none;
}

.item:hover{
    background: var(--green);
    cursor: pointer;
}

.icon{
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 10%;
    background: var(--black);
    color: var(--white);
}
a:hover{
    color: rgb(61, 150, 61);
}
.img{
    width: 1.5rem;
}

.text{
    text-align: justify;
    padding: 0 10% 1% 10%;
}

@media (max-width: 900px) {
    .dropdownContainer{
        width: 80%;
        padding: 10% 0;
    }

}

@media (max-width: 600px){
    .dropdownContainer{
        width: 100%;
        padding: 10% 0;
    }
    .img{
        width: 1.5rem;
    }
    .icon{
        width: 18%;
    }
}
