.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 5%;
    min-height: 50vh;
    justify-content: space-between;
}

.navbar{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.buttons{
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.text{
    width: 50%;
    text-align: center;
    margin-bottom: 2rem;

}

.pages{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 2% 0 0 0;
    width: 40%;

}

.arrow{
    color: var(--black);
    width: 45%;
}

.arrow:hover{
    color: var(--green);
    cursor: pointer;
}