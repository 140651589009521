.black {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background: var(--black);
    padding: 0.7rem 2rem;
    border: none;
    border-radius: 5px;
    color: var(--white);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 800;
}

.black:hover:enabled {
    background: var(--white);
    color: var(--green);
    box-shadow: 0 20px 20px 0 rgba(132, 144, 255, 0.3);
}

.black2 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background: var(--black);
    padding: 0.7rem 2rem;
    border: none;
    border-radius: 5px;
    color: var(--white);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 800;
}

.black2:hover:enabled {
    background: var(--green);
    color: var(--black);
    box-shadow: 0 20px 20px 0 rgba(132, 144, 255, 0.3);
}

.green {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background: var(--green);
    padding: 0.7rem 2rem;
    border: none;
    border-radius: 5px;
    color: var(--white);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 800;
}

.green:hover:enabled {
    background: var(--green);
    color: var(--black);

}

.circle{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background: var(--black);
    padding: 0.7rem;
    border: none;
    border-radius: 50%;
    color: var(--white);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 30px;
    font-weight: 800;
}

.circle:hover{
    transform: scale(1.2);
    transition: all 0.3s ease 0s;
}