.page{
    padding: 5% 10%;
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: space-between;
}

.column{
    display: flex;
    flex-direction: column;
    width: 48%;
}

.box{
    display: flex;
    flex-direction: column;
}

@media (max-width: 600px) {

}