.container{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding: 0 5%;
    /* max-width: 1280px; */
    /* margin: auto; */
}

.slider{
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 2% 10%;
}

.bgimage{
    width: 100%;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

}

.image{
    width: 100%;
    height: 40rem;
    object-fit: cover;
    object-position: top;
}

.arrows{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: var(--black);
    color: var(--green);
    padding: 1rem;
    border-radius: 50%;
    margin: 3%;
    z-index: 2;
}

.arrows:hover{
    cursor: pointer;
    background: var(--green);
    color: var(--black);
}

.icon{
    display: inline-block;
    height: 3.1rem;
    width: 3.1rem;
    background-color: var(--black);
    border-radius: 50%;
    text-align: center;
    font-size: 1.5rem;
    line-height: 3.5rem;
    margin-right: 1rem;
}

.margin{
    margin: 0 2%;
}

@media (max-width: 767px) {
    .container {
        padding: 0;
        margin-bottom: 5%;
    }
    .slider{
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        padding: 0 0 5% 0;
    }
    .image{
        width: 90%;
        height: 25rem;
    }

}