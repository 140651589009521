.navbar {
    display: flex;
    align-items: center;
    background: transparent;
    height: 80px;
    z-index: 3;
    flex-direction: row;
    justify-content: space-between;
    /*permette di manterene la navbar sempre in cima anche se si scorre la pagina*/
    top: 0;
    position: sticky;
    padding: 20px 10%;
    transition: all 0.5s;
}

.navbarActive{
    display: flex;
    align-items: center;
    background: var(--green);
    box-shadow: -21.213px 21.213px 30px 0 rgba(158, 158, 158, 0.3);
    height: 80px;
    z-index: 3;
    flex-direction: row;
    justify-content: space-between;
    top: 0;
    position: sticky;
    padding: 20px 10%;
    transition: all 0.5s;
}

.navbarActive:hover{
    display: flex;
    align-items: center;
    background: var(--green);
    box-shadow: -21.213px 21.213px 30px 0 rgba(158, 158, 158, 0.3);
    height: 80px;
    z-index: 3;
    flex-direction: row;
    justify-content: space-between;
    top: 0;
    position: sticky;
    padding: 20px 10%;
    transition: all 0.5s;
}

.logo{
    cursor: pointer;
    width: 7rem;
}

.logoStudio{
    cursor: pointer;
    width: 5rem;
    margin-left: 0.5rem;
    margin-top: -4px;
}

.logoMobile{
    cursor: pointer;
    width: 5rem;
}

.menu{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    top: 0;
}

.item{
    color: var(--white);
    padding: 0.5rem;
    text-decoration: none;
    font-weight: 800;
    font-size: 20px;
    text-transform: uppercase;
    display: flex;
    align-items: center;

}

.item:hover{
    color: var(--green);
    cursor: pointer;
}

.item:active{
    color: var(--green);
    cursor: pointer;
}

.itemActive{
    color: var(--green);
    padding: 0.5rem;
    text-decoration: none;
    font-weight: 800;
    font-size: 20px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
}

.itemBlack{
    color: var(--black);
    padding: 0.5rem;
    text-decoration: none;
    font-weight: 800;
    font-size: 20px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
}

.itemBlack:hover{
    color: var(--white);
    padding: 0.5rem;
    text-decoration: none;
    font-weight: 800;
    font-size: 20px;
    text-transform: uppercase;
}

.alignCenter{
    display: flex;
    flex-direction: row;
    align-items: center;
}



@media (max-width: 1366px){
    .navbar {
        padding: 20px 5%;
    }
    .navbarActive{
        padding: 20px 5%;
    }

    .navbarActive:hover{
        padding: 20px 5%;
    }


    .logo{
        cursor: pointer;
    }
}

@media (max-width: 600px) {
    .navbar {
        display: flex;
        align-items: center;
        background: transparent;
        /*height: 40px !important;*/
        z-index: 3;
        flex-direction: row;
        justify-content: space-between;
        /*permette di manterene la navbar sempre in cima anche se si scorre la pagina*/
        top: 0;
        position: sticky;
        padding: 20px 0 !important;
        transition: all 0.5s;
    }

    .navbarActive{
        display: flex;
        align-items: center;
        background: var(--green);
        box-shadow: -21.213px 21.213px 30px 0 rgba(158, 158, 158, 0.3);
        height: 40px !important;
        z-index: 3;
        flex-direction: row;
        justify-content: space-between;
        top: 0;
        position: sticky;
        padding: 20px 0 !important;
        transition: all 0.5s;
    }

    .logoMobile{
        cursor: pointer;
        width: 5rem;
        margin-left: 2rem;
    }

    .menu{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background: rgba(0, 0, 0, 0.8);
        width: 100%;
        height: 100vh;
        position: fixed;
        top: 0;
        right:0;
        padding-top: 3.5rem;
        color: var(--white);
        transition: transform 0.3s ease-in-out;

    }
    .item{
        display: flex;
        justify-content: center;
        color: var(--white);
        text-align: center;
        padding: 2rem;
    }
    .itemActive{
        display: flex;
        justify-content: center;
        color: var(--green);
        text-align: center;
        padding: 1rem;
    }
}